/*css is organized top-down as it appears on the page*/

* {
    word-break: break-word;
}

body {
    background-color: rgb(0, 0, 0);
    box-sizing: border-box;
    max-width: 100%;
}

.video-overlay {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url("/assets/images/bg-overlay.png");
    background-size: cover;
}

.video {
    position: fixed;
    z-index: 0;
    min-width: 100%;
    min-height: 100%;
}

.App {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    .mobile-background {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url("/assets/images/mobile-background.png");
        background-size: cover;
    }

    .inner {
        width: 100%;
        height: 100%;
        flex-direction: column;
        z-index: 2;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: 650px) {
            padding: 1em;
        }

        .part1,
        .part2,
        .part3,
        .part4,
        .part5,
        .part6 {
            padding: 1em;

            @media (min-width: 650px) {
                padding: 0 !important;
            }
        }

        .part1 {
            margin-top: 3em;

            @media (min-width: 500px) {
                margin-top: 0 !important;
            }
        }

        .part2 {
            margin-top: 6em;

            @media (min-width: 650px) {
                margin-top: 14em;
            }
        }

        .part3 {
            margin-top: 6em;

            @media (min-width: 650px) {
                margin-top: 14em;
            }
        }

        .part4 {
            margin-top: 6em;

            @media (min-width: 650px) {
                margin-top: 20em;
            }
        }

        .part5 {
            margin-top: 6em;

            @media (min-width: 650px) {
                margin-top: 13em;
            }
        }

        .part6 {
            margin-top: 8em;
        }
    }
}
