/*@font-face {
  font-family: "eurostile";
  src: url("/assets/fonts/EuroStyle_Normal.ttf") format("truetype");
}*/

@font-face {
  font-family: "inconsolata";
  src: url("/assets/fonts/Inconsolata_Expanded-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "led-board-7";
  src: url("/assets/fonts/led_board-7.ttf") format("truetype");
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  color: white;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  min-width: 375px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
  font-family: "inconsolata";
  text-rendering: optimizeLegibility;
  --webkit-font-smoothing: antialiased;
}
