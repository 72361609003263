.Part5 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;

    .title-mobile {
        flex-direction: column;

        .japanese {
            margin-bottom: 0.6em;
        }
    }

    .title {
        display: flex;
        max-width: 100%;

        @media (min-width: 650px) {
            margin-right: auto;
            margin-left: 10%;
        }

        div:nth-child(2) {
            margin-left: 0.3em;
            font-family: "eurostile";
            text-align: center;
            font-size: 40px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 0.025em;
        }

        .japanese {
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
        }

        div {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            letter-spacing: 0.025em;
            margin-left: 0.6em;
        }
    }

    .partners {
        margin-top: 4em;
        position: relative;

        @media (min-width: 650px) {
            margin-top: 3em;
        }

        .partners-img {
            height: 2em;
            position: relative;
            background-size: auto;
            background-repeat: repeat-x;
            background-image: url("../assets/images/partners.png");

            @media (min-width: 700px) {
                background-size: contain;
            }
        }

        // animation: slide 30s linear infinite;
    }

    @keyframes slide {
        0% {
            transform: translate(0);
        }
        100% {
            transform: translate(); /* The image width */
        }
    }

    .text {
        width: 47em;
        max-width: 100%;
        text-align: center;
        letter-spacing: 0.025em;
        margin-top: 3em;

        @media (min-width: 650px) {
            margin-left: 5%;
            margin-top: 7em;
            font-size: 0.7em;
            text-align: justify;
        }

        @media (min-width: 800px) {
            margin-left: 20%;
        }

        @media (min-width: 1060px) {
            margin-left: 50%;
        }

        .email {
            border-bottom: 1px solid grey;
        }
    }
}
