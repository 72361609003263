.Part2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
    align-items: center;

    .title {
        font-family: "eurostile";
        font-size: 2.4em;
        font-weight: 700;
        text-align: center;
        display: flex;
        align-items: center;
        margin-top: 0.3em;
        letter-spacing: 0.025em;
    }

    .alm_picture_container {
        margin-top: 3.5em;
        z-index: 100;
        display: flex;
        max-width: 100%;
        width: 100%;
        overflow-x: auto;

        .alm_picture {
            max-height: 600px;
        }
    }

    .overlay_alm-picture-zoom {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        top: 0;
        left: 0;
        z-index: 99;
    }

    .bullet_points {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        max-width: 70em;
        flex-wrap: wrap;
        margin-top: 3em;

        .bullet_point {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            max-width: 10em;
            min-width: 10em;

            @media (min-width: 500px) {
                max-width: 13em;
                min-width: 13em;
            }

            @media (min-width: 650px) {
                max-width: 15em;
                min-width: 15em;
            }

            @media (min-width: 750px) {
                max-width: 17em;
                min-width: 17em;
            }

            @media (min-width: 840px) {
                max-width: 19em;
                min-width: 19em;
            }

            @media (min-width: 940px) {
                max-width: 21em;
                min-width: 21em;
            }

            @media (min-width: 1020px) {
                max-width: 15em;
                min-width: 15em;
            }

            padding: 1em;
            letter-spacing: 0.035em;

            .number {
                background: rgba(0, 0, 0, 0.62);
                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 100%;
                height: 2em;
                width: 2em;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .text {
                font-size: 12px;
                margin-top: 1.5em;

                @media (min-width: 650px) {
                    font-size: 0.7em;
                }
            }
        }
    }
}
