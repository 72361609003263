.Part6 {
    width: 100%;
    display: flex;
    flex-direction: column;

    .body {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        position: relative;

        .diamond1 {
            position: absolute;
            display: flex;
            top: -3px;
            left: -5px;
        }

        .diamond2 {
            position: absolute;
            display: flex;
            top: -3px;
            right: -5px;
        }

        .diamond3 {
            position: absolute;
            display: flex;
            bottom: -3px;
            left: -5px;
        }

        .diamond4 {
            position: absolute;
            display: flex;
            bottom: -3px;
            right: -5px;
        }

        .diamond {
            width: 0;
            height: 0;
            border: 3px solid transparent;
            border-bottom-color: #ffffff29;
            position: relative;
            top: -3px;
        }

        .diamond:after {
            content: "";
            position: absolute;
            left: -3px;
            top: 3px;
            width: 0;
            height: 0;
            border: 3px solid transparent;
            border-top-color: #ffffff29;
        }

        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.04) 75.63%,
            rgba(255, 255, 255, 0) 100%
        );
        padding-top: 3em;
        padding-bottom: 1em;
        padding-left: 1em;
        padding-right: 1em;
        border-top: 1px solid rgba(255, 255, 255, 0.16);
        border-bottom: 1px solid rgba(255, 255, 255, 0.16);

        .uxd-presentation {
            display: flex;
            flex-direction: column;
            width: 30em;
            max-width: 30em;
            align-items: flex-start;

            img {
                height: 2em;
            }

            div {
                margin-top: 2em;
                text-align: left;
                font-weight: 200;
                max-width: 20em;
                letter-spacing: 0.035em;

                @media (min-width: 650px) {
                    text-align: center;
                }

                @media (min-width: 800px) {
                    text-align: left;
                }
            }
        }

        .links {
            display: flex;
            flex-direction: row;
            margin-top: 3em;
            flex-wrap: wrap;

            a:hover {
                opacity: 0.9;
            }

            @media (min-width: 1260px) {
                margin-top: 0 !important;
            }

            @media (min-width: 800px) {
                flex-direction: row;
            }

            .title {
                font-size: 1.1em;
                font-weight: bold;
                letter-spacing: 0.035em;
                margin-bottom: 1em;
                max-width: 100%;

                @media (min-width: 800px) {
                    margin-bottom: 0;
                }
            }

            .link {
                margin-top: 1.5em;
            }

            .about-us,
            .find-us {
                padding-bottom: 3em;
            }

            .about-us {
                display: flex;
                flex-direction: column;
                width: 10em;
                align-items: flex-start;

                @media (min-width: 650px) {
                    width: 15em;
                }
            }

            .find-us {
                display: flex;
                flex-direction: column;
                width: 10em;
                align-items: flex-start;

                @media (min-width: 650px) {
                    width: 15em;
                }
            }

            a {
                font-family: "inconsolata";
                font-size: 16px;
                font-weight: 200;
                line-height: 17px;
                letter-spacing: 0.035em;
                text-align: left;
            }
        }

        .vesting {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 0.9em;

            .streamflow {
                display: flex;
                align-items: center;
                padding-bottom: 3em;

                @media (min-width: 800px) {
                    margin-top: 0 !important;
                }

                img {
                    height: 3em;
                    filter: grayscale(1);
                    padding: 0.4em;
                }

                span {
                    color: #cdd7e3;
                    margin-left: 0.4em;

                    span {
                        color: white;
                        position: relative;
                        right: 0.5em;
                    }
                }
            }
        }
    }

    .footer {
        width: 100%;
        height: 2.5em;
        display: flex;
        justify-content: space-between;

        margin-top: 1em;

        .copyright {
            font-size: 12px;
            font-weight: 200;
            line-height: 13px;
            letter-spacing: 0.035em;
        }

        .term-of-use {
            font-size: 12px;
            font-weight: 200;
            line-height: 13px;
            letter-spacing: 0.035em;
        }
    }
}
