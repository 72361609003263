@import "../styles/uxd_theme";

@keyframes expand-animation {
  0% {
    height: 0em;
    opacity: 0;
  }
  100% {
    height: 13.6em;
    opacity: 1;
  }
}

@keyframes collapse-animation {
  0% {
    height: 12.5em;
    opacity: 1;
  }
  100% {
    height: 0em;
    opacity: 0;
  }
}

.Header {
  display: flex;
  width: 100%;
  max-width: 100%;
  letter-spacing: 0.035em;
  height: 63vh;
  align-items: flex-start;

  @media (min-width: 550px) {
    height: 74vh;
  }

  @media (min-width: 650px) {
    height: auto;
  }

  .collapse-animation {
    animation-name: collapse-animation;
    animation-duration: 200ms;
    animation-timing-function: ease-in;
    overflow: hidden;
  }

  .expand-animation {
    animation-name: expand-animation;
    animation-duration: 0.32s;
    animation-timing-function: ease-out;
    overflow: hidden;
  }

  .content-expanded {
    background: rgba(255, 255, 255, 0.04);
    border-bottom: 1px solid rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(20px);
  }

  .content {
    display: flex;
    width: 100%;
    padding-top: 1em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    flex-direction: column;
    align-items: center;

    // height: 5em;
    // transition: height 1s ease-out;

    @media (min-width: 650px) {
      height: auto;
      padding-right: 40px;
      flex-direction: row !important;
      background: transparent;
      border-bottom: 0;
      backdrop-filter: none;

      .about_us {
        margin-top: 0 !important;
      }

      .find_us {
        margin-left: 2em !important;
        margin-top: 0 !important;
      }

      .webapp {
        margin-left: 3em !important;
        margin-top: 0 !important;
      }

      .icon {
        margin-left: 0.4em !important;
        margin-top: 0 !important;
      }
    }

    .head-section {
      width: 100%;
      height: 3em;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (min-width: 650px) {
        width: auto;
      }

      .uxd-logo {
        height: 2em;
        width: 2em;
        max-height: 100%;
      }

      .hero-icon {
        height: 1.7em;
        padding: 0.5em;
        cursor: pointer;

        @media (min-width: 650px) {
          display: none;
        }
      }

      .white-cross-icon {
        height: 2em;
        cursor: pointer;

        @media (min-width: 650px) {
          display: none;
        }
      }
    }

    .links-section {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      @media (min-width: 650px) {
        flex-direction: row;
        flex-grow: 1;
        align-items: center;
        width: auto;
      }
    }

    .dropdown {
      display: flex;
      flex-direction: column;

      a {
        font-size: 0.8em;
        cursor: pointer;
        white-space: nowrap;
        margin-top: 2em;
        margin-left: 2em;
        text-transform: capitalize;

        @media (min-width: 650px) {
          margin-top: 0 !important;
          margin-left: 0 !important;
        }
      }

      a:hover {
        opacity: 0.9;
      }

      @media (min-width: 650px) {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        border: 1px solid grey;
        min-height: 10em;
        width: auto;
        margin-top: 0.5em;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 1em;
      }
    }

    .title {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .title:hover {
      opacity: 0.9;
    }

    .about_us,
    .find_us,
    .webapp-btn {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 16px;
      text-transform: uppercase;

      @media (min-width: 650px) {
        padding-left: 16px;
        text-transform: none;
      }
    }

    .about_us,
    .find_us {
      margin-top: 1em;
      position: relative;

      .text {
        margin-left: 1em;
      }
    }

    .webapp {
      width: 100%;
      margin-top: 1.5em;
      display: flex;
      align-items: center;
      padding-bottom: 1em;

      .open-text {
        width: auto;
        white-space: nowrap;
        padding-right: 1em;
        color: #05ffff;

        font-weight: 800;
        letter-spacing: 0.035em;
        text-transform: uppercase;
      }

      @media (min-width: 650px) {
        width: auto;
        padding-bottom: 0;
      }

      .webapp-btn {
        border-bottom: 1px solid #05ffff;
        font-weight: 800;
        height: 4em;

        border: 1px solid #05ffff;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: 650px) {
          border-left: 0 !important;
          border-top: 0 !important;
          border-right: 0 !important;
          width: auto;
          height: auto;
        }
      }
    }

    .icon {
      font-weight: 400;

      @media (min-width: 650px) {
        margin-top: 0.2em !important;
        margin-left: 0.7em !important;
      }
    }
  }
}
