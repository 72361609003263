@media (min-width: 1300px) {
    .Part1 {
        flex-direction: row !important;

        .left,
        .right {
            width: 45% !important;
        }

        .right {
            margin-top: 0;
        }
    }
}

.Part1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;

    @media (min-width: 650px) {
        flex-wrap: wrap;
        padding: 1em;
    }

    .left {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;

        @media (min-width: 650px) and (max-width: 1300px) {
            margin-top: 6em;
            align-items: center;

            .description {
                align-items: center;
            }
        }

        @media (min-width: 1300px) {
            align-items: flex-start;
            margin-top: 0;
        }

        .title {
            font-family: "eurostile";
            font-size: 2.8em;
            font-weight: 400;
            letter-spacing: 0.025em;
            text-align: left;

            @media (min-width: 500px) {
                font-size: 3.5em;
            }

            @media (min-width: 650px) {
                font-size: 4em;
                text-align: center;
            }

            @media (min-width: 1300px) {
                text-align: initial;
            }
        }

        .description {
            margin-top: 1em;
            display: flex;
            flex-direction: column;
            max-width: 100%;
            text-align: left;
            font-size: 15px;
            font-weight: 200;
            letter-spacing: 0.035em;
            line-height: 25px;
            padding-left: 0.1em;
            padding-right: 0.1em;
            justify-content: center;

            @media (min-width: 390px) {
                font-size: 16px;
            }

            @media (min-width: 500px) {
                font-size: 20px;
            }

            @media (min-width: 650px) {
                padding-left: 0;
                padding-right: 0;
                text-align: center;
            }

            .asset-liability-management-module-text {
                width: auto;
                display: flex;

                @media (min-width: 450px) {
                    white-space: nowrap;
                    flex-direction: row;
                    margin-top: 0 !important;
                }

                @media (min-width: 650px) {
                    white-space: nowrap;
                    margin-top: 0.5em !important;
                }

                div {
                    border-bottom: 1px dashed white;
                    padding-bottom: 0.4em;
                }
            }

            @media (min-width: 620px) {
                font-size: 24px;
            }

            @media (min-width: 1300px) {
                text-align: initial;
            }

            color: rgba(255, 255, 255, 0.71);
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        margin-top: 4em;
        justify-content: center;
        align-items: center;
        width: 100%;

        @media (min-width: 650px) {
            width: auto;
        }

        .bloc:nth-child(1) {
            margin-top: 6em;

            @media screen and (min-width: 1300px) {
                left: -3em;
            }

            position: relative;
            left: 0em;

            @media (min-width: 650px) {
                margin-top: 0;

                .col_1 {
                    align-items: flex-start;
                    height: 13em;
                }
            }
        }

        .bloc:nth-child(2) {
            margin-top: 2em;

            @media (min-width: 650px) {
                margin-top: 0;

                .col_1 {
                    align-items: flex-start;
                    height: 11em;
                }

                position: relative;
                top: -1.3em;
                left: 4em;
                margin-top: 0 !important;
            }

            @media (min-width: 1300px) {
                position: relative;
                top: -1.3em;
                left: 8em;
                margin-top: 0 !important;
            }
        }

        .bloc-regular {
            background: rgba(0, 0, 0, 0.18);
            border: 1px solid rgba(255, 255, 255, 0.09);
            box-shadow: inset -16px -11px 35px rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(16px);
            border-radius: 0px 5px 0px 28px;
            display: flex;
            font-weight: 200;
            letter-spacing: 0.035em;
            padding-left: 1em;
            padding-top: 1em;
            padding-bottom: 1em;
            padding-right: 1em;
            flex-direction: row;
            align-items: flex-start;
            width: 26em;
            min-width: 26em;

            .col_1 {
                min-width: 10em;
                display: flex;
                flex-direction: column;
                align-items: center;

                .title {
                    height: 3em;
                    letter-spacing: 0.035em;
                }

                div:nth-child(2) {
                    font-size: 1.4em;
                    font-weight: 200;
                    line-height: 25px;
                    letter-spacing: 0.035em;
                }

                div:nth-child(3) {
                    font-size: 0.7em;
                    font-weight: 400;
                    margin-bottom: 1em;
                }

                .logo {
                    height: 3em;
                }

                .icon {
                    height: 4em;
                    width: 4em;

                    @media (min-width: 650px) {
                        margin-top: auto;
                    }
                }
            }

            .col_2 {
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                justify-content: space-between;

                .links {
                    display: flex;
                    flex-direction: row;
                    flex-grow: 1;
                    justify-content: space-between;
                    margin-top: 1em;

                    @media (min-width: 650px) {
                        margin-top: 0 !important;
                    }

                    .arrow_link {
                        display: flex;
                        flex-direction: column;
                        white-space: nowrap;
                        font-size: 0.8em;
                        letter-spacing: 0.035em;

                        img {
                            height: 1em;
                            width: 1em;
                        }

                        span {
                            margin-top: 0.3em;
                        }

                        cursor: pointer;
                    }

                    .arrow_link:hover {
                        opacity: 0.9;
                    }
                }

                .text {
                    margin-top: 1em;
                    display: flex;
                    flex-direction: row;
                    flex-grow: 1;
                    text-align: justify;
                    font-size: 12px;
                    font-weight: 200;
                    line-height: 13px;
                    letter-spacing: 0.035em;
                }
            }
        }

        .bloc-mobile {
            background: rgba(0, 0, 0, 0.18);
            border: 1px solid rgba(255, 255, 255, 0.09);
            box-shadow: inset -16px -11px 35px rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(16px);
            border-radius: 0px 5px 0px 28px;
            display: flex;
            flex-direction: row;
            max-width: 100%;
            width: 100%;
            font-weight: 200;
            letter-spacing: 0.035em;
            padding-left: 1em;
            padding-top: 1em;
            padding-right: 1em;
            padding-bottom: 2em;
            flex-grow: 1;

            .col_1 {
                min-width: 10em;
                display: flex;
                flex-direction: column;

                .title {
                    height: 4em;
                    letter-spacing: 0.035em;
                    width: 7em;
                }

                div:nth-child(2) {
                    font-size: 1.4em;
                    font-weight: 200;
                    line-height: 25px;
                    letter-spacing: 0.035em;
                }

                div:nth-child(3) {
                    font-size: 0.7em;
                    font-weight: 400;
                    margin-bottom: 1em;
                }

                .text {
                    margin-top: 2em;
                    display: flex;
                    flex-direction: row;
                    flex-grow: 1;
                    text-align: justify;
                    font-size: 12px;
                    font-weight: 200;
                    line-height: 13px;
                    letter-spacing: 0.035em;
                    max-width: 80%;
                }
            }

            .col_2 {
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;

                .links {
                    display: flex;
                    flex-direction: column;

                    .arrow_link {
                        display: flex;
                        flex-direction: column;
                        white-space: nowrap;
                        font-size: 0.8em;
                        letter-spacing: 0.035em;
                        margin-bottom: 1em;

                        img {
                            height: 1em;
                            width: 1em;
                        }

                        span {
                            margin-top: 0.3em;
                        }

                        cursor: pointer;
                    }

                    .arrow_link:hover {
                        opacity: 0.9;
                    }
                }

                .logo {
                    height: 3em;
                }

                .icon {
                    height: 4em;
                    width: 4em;

                    @media (min-width: 650px) {
                        margin-top: auto;
                    }
                }
            }
        }
    }

    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10em;

        @media (min-width: 650px) {
            margin-top: 14em;
        }

        margin-left: auto;
        margin-right: auto;
        letter-spacing: 0.08em;

        img {
            height: 1.2em;
            width: 1.2em;
            position: relative;
            top: 0.3em;
            margin-left: 0.5em;
        }

        div:nth-child(2) {
            margin-left: 3em;
        }

        .optimism-icon {
            filter: grayscale(1);
        }
    }
}
