.Part4 {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto;
    align-items: center;
    position: relative;

    .cube {
        position: absolute;
        left: 0;
        top: 3em;
        z-index: 1;
        object-fit: fill;
        opacity: 0.3;
        height: 55em;
    }

    .title-mobile {
        flex-direction: column;

        .japanese {
            margin-bottom: 0.6em;
        }
    }

    .title {
        display: flex;
        letter-spacing: 0.025em;

        @media (min-width: 650px) {
            margin-right: auto;
            margin-left: 10%;
        }

        .title-text {
            margin-left: 0.3em;
            font-family: "eurostile";
            font-weight: 700;
            letter-spacing: 0.025em;
            text-align: center;
            font-size: 40px;
        }

        .japanese {
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
        }

        div {
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            letter-spacing: 0.025em;
            margin-left: 0.6em;
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 2em;
        align-items: center;

        @media (min-width: 650px) {
            flex-direction: row;
            align-items: flex-start;
        }

        .text {
            max-width: 100%;
            margin-top: 4em;
            text-align: center;
            width: 100%;
            margin-right: 0;

            @media (min-width: 650px) {
                max-width: 22%;
                margin-left: 10%;
                text-align: left;
                margin-top: 6em;
                width: auto;
                font-size: 0.7em;
                margin-right: 4em;
            }

            .reach-out {
                border-bottom: 1px solid grey;
            }
        }

        .investors {
            display: flex;

            max-width: 50em;
            width: 100%;
            margin-top: 3em;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (min-width: 650px) {
                flex-direction: row;
                flex-wrap: wrap;
                align-items: initial;
                width: 100%;
                margin-top: 0 !important;
            }

            @media (min-width: 1000px) {
                justify-content: flex-start;
            }

            .investor {
                width: 16em;
                height: auto;
                margin: 1em;
            }
        }
    }
}
