.Part3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;

    @media (min-width: 650px) {
        flex-wrap: wrap;
    }

    .japanese {
        font-family: "eurostile";
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.025em;
        text-align: center;
    }

    .title {
        font-family: "eurostile";
        font-size: 3em;

        @media (min-width: 650px) {
            font-size: 4em;
        }

        font-weight: 700;
        letter-spacing: 0.025em;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .text {
        text-align: center;
        max-width: 50em;
        margin-top: 3em;
        letter-spacing: 0.035em;
    }

    .dashboard-container {
        display: flex;
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
        margin-top: 3em;

        @media (min-width: 650px) {
            margin-top: 8em;
        }

        @media (min-width: 950px) {
            justify-content: center;
        }

        .dashboard {
            display: flex;
            flex-direction: column;
            border: 1px solid #073333;
            align-items: center;
            border-radius: 0.5em;
            position: relative;
            min-width: 950px;

            .cylinder {
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
                object-fit: fill;
                opacity: 0.35;
            }

            .dashboard-header {
                display: flex;
                width: 100%;
                padding-top: 0.2em;
                padding-bottom: 0.2em;
                padding-left: 1em;
                padding-right: 0.2em;
                border-bottom: 1px solid #073333;
                background-color: #073333;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .title {
                    color: #00ffff;
                    font-size: 0.7em;
                    font-family: "led-board-7";
                    letter-spacing: 0.035em;
                }

                .cross {
                    height: 1.5em;
                    width: 1.5em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .dashboard-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding-top: 2em;
                padding-bottom: 2em;
                padding-left: 6em;
                padding-right: 6em;
                z-index: 2;
                background-color: rgba(0, 0, 0, 0.4);

                .icon {
                    width: 8em;
                }

                .dashboard-img {
                    margin-top: 2em;
                    width: 100%;
                    max-width: 60em;
                }

                .dashboard-link {
                    color: #00ffff;
                    font-size: 0.8em;
                    margin-top: 3em;
                    letter-spacing: 0.035em;

                    img {
                        position: relative;
                        top: 0.4em;
                        right: 0.2em;
                    }
                }
            }
        }
    }
}
